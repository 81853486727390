body {
  align-items: center;
  background-color: $background-light;
  color: $text-light;
  display: flex;
  flex-flow: column;
  font-family: "Source Sans Pro", "Helvetica", "Sans";
  justify-content: center;

  @include dark(){
  background-color: $background-dark;
  color: $text-dark;
  }
}
