a {
  font-size: rem(20px);
  text-decoration: none;

  &,
  &:visited {
    color: $text-light;

    @include dark() {
      color: $text-dark;
    }
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}
